<template>
  <b-row class="protocol--tab">
    <b-col cols="12" md="8">
      <p>Elije que protocolo activar al momento de gestionar tus envíos con incidencias <i class="fa fa-info-circle styleIcon protocol--config-icon" variant="success"></i></p>
      <p>Se ejecutará de forma automatizada y masiva cuando los envíos se encuentren en las etapas descritas en la leyenda.</p>
      <b-card>
        <b-form-checkbox
          switch
          v-if="!configProtocol.loading"
          class="protocol--tab--switch"
          @change="changeSwitch($event, 'retry')" 
          :disabled="userRoleAdmin"
          v-model="configProtocol.retryProtocol">Insistencia</b-form-checkbox>
          <b-skeleton v-if="configProtocol.loading" width="30%" height="1.5rem"/>
        <b-form-checkbox
          switch 
          v-if="!configProtocol.loading"
          class="protocol--tab--switch"
          @change="changeSwitch($event, 'return')"
          :disabled="userRoleAdmin"
          v-model="configProtocol.returnProtocol">Devolución</b-form-checkbox>
         <b-skeleton v-if="configProtocol.loading" width="30%" height="1.5rem"/>
      </b-card>
      <p class="protocol--config-text" >*Política de devoluciones
      <br>Costos de transporte : Deberán ser asumidos por el usuario incluyendo el transporte de vuelta. </p>
    </b-col>
    <b-col cols="12" md="4">
      <card-info></card-info>
    </b-col>
  </b-row>
</template>
<script>
import CardInfo from './CardInfo.vue'
export default {
  props: ['configProtocol'],
  components: {CardInfo},
  watch: {

  },
  computed: {
    userRoleAdmin() {
      return this.$session.get('cas_user').role === 'admin'
    }
  },
  methods: {
    changeSwitch(value, source) {
      if (!this.userRoleAdmin) {
        this.configProtocol.loading = true
        const valueStatusProtocol = !!value ? 'activate' : 'desactivate'
        let valueStatusCode = ''
        switch (source) {
        case 'retry':
          valueStatusCode = 'sellers_retry_rule'
          break
        case 'return':
          valueStatusCode = 'sellers_return_rule'
          break
        }
        const data = {
          code: valueStatusCode,
          status_protocol: valueStatusProtocol
        }
        this.$emit('postDataProtocol', data)
      }
    }
  }
}
</script>
<style lang="scss">
.protocol--tab {
  .protocol--info-icon,
  .protocol--config-icon {
    font-size: 3.4rem;
    color: #1B4284;
  }
  .protocol--config-icon {
    font-size: 1.3rem;
  }
  .protocol--tab--switch .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #0FA828;
    background-color: #0FA828;
  }
  .custom-control-label {
    font-size: 1rem;
  }
}
</style>